import { ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { CableType, STELLA_LAST_CHART_SCALE } from '@app/enums';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { EmgCalibrationComponent } from '@app/training/components/emg-calibration/emg-calibration.component';
import { StepTestController } from '@app/training/pages/custom-diagnostic-test/CustomTest';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { TestController } from '@app/training/TestController';
import { ChartLine, ChartPoint, Line, MovementType, SoundInfo, DiagnosticTestPort, SelectedMuscle, ChannelSignal } from '@app/types';
import { Player } from '@app/utils/player';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { delay, filter, flatMap, map, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseContainerComponent } from '@app/training/components/exercise-container/exercise-container.component';
import { MessageDialogComponent } from '@app/components/message-dialog/message-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { BatteryService } from '@app/shared/services/low-battery.service';
import { BaseEmgComponent } from './baseEmg.component';
import { CableWarningDialogComponent } from '../components/cable-warning-dialog/cable-warning-dialog.component';
import { ExercisesQuery } from '@app/store/exercises';
import { Exercise } from '@app/models/Exercise.model';
import { ActionDispatcher } from '../ActionDispatcher';
import { LineChart2 } from '@app/charts/LineChart2';
import { getColorForChannel } from '@app/utils/ColorDictionary';
import { BaseChartController } from '../services/chart-controllers/BaseChartController';
import { InitializeExerciseDialogComponent } from '../../components/initializing-exercise-dialog/initialize-exercise-dialog.component'

interface DiagnosticVals {
  max: number;
  ports: DiagnosticTestPort[];
}
export abstract class BaseDiagnosticComponent extends BaseEmgComponent {
  private cableDialogRef: MatDialogRef<any>;
  protected initializeExerciseDialog: MatDialogRef<InitializeExerciseDialogComponent>;

  trainingReady: boolean = false;
  initialSetupDone = false;
  muted = false;
  testController: TestController;
  player = new Player();
  maxes: number[];
  vals$ = new BehaviorSubject<DiagnosticVals>({
    max: +localStorage.getItem(STELLA_LAST_CHART_SCALE),
    ports: [],
  });
  selectedChannel = 0;

  steps = [];
  test: Exercise;
  guideLine: ChartPoint[][];
  @ViewChild(ExerciseContainerComponent)
  exerciseContainer: ExerciseContainerComponent;

  constructor(
    stellaDirect: StellaDirectService,
    protected readonly sessionService: CurrentSessionService,
    exerciseService: CurrentExerciseService,
    private exerciseQuery: ExercisesQuery,
    dashboard: DashboardService,
    protected readonly dialog: MatDialog,
    location: Location,
    router: Router,
    protected readonly translation: TranslateService,
    private route: ActivatedRoute,
    batteryService: BatteryService
  ) {
    super(
      stellaDirect,
      exerciseService,
      dashboard,
      location,
      router,
      batteryService
    );
    const isPelvic = localStorage.getItem("CURRENT_CONCEPT") === "pelvic";
    if (isPelvic) {
      this.selectedChannel = 6;
    }

    this.test = {
      steps: {},
    } as any;
  }

  async initializeExercise() {
    const template = {
      ...this.test,
      steps: {
        ...this.test.steps,
        fixedRestTime: true,
        steps: [...this.steps],
      },
    };
    await this.exerciseService.initializeExercise(this.test.name, {
      module: `diag.${this.test.name}`,
      template,
      primary: this.selectedChannel,
      emgCalibration: this.maxes,
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.add(
      this.route.data.subscribe((data) => {
        if (data.exerciseName) {
          console.log(
            `Loading statically named exercise ${data.exerciseName}. Data: `,
            data
          );
          this.test = this.exerciseQuery
            .getAll()
            .filter((ex) => ex.name === data.exerciseName)[0];
        } else if (data.paramName) {
          console.log(
            `Loading dynamically named exercise from parameter ${data.paramName}. Data: `,
            data
          );

          this.subscriptions.add(
            this.route.paramMap
              .pipe(
                map((params) => params.get(data.paramName)),
                map((name) => {
                  console.log(`Loading exercise named ${name}.`);
                  return this.exerciseQuery
                    .getAll()
                    .filter((ex) => ex.name === name)[0];
                }),
                tap((test) => (this.test = test))
              )
              .subscribe((_) => {
                // TODO: (copied from custom-diagnostic-test) what this logic do?
                if (this.sub) {
                  this.sub.unsubscribe();
                }
              })
          );
        }
      })
    );

    this.subscriptions.add(
      this.stellaDirect.cable$.subscribe((c) => {
        if (
          this.trainingReady &&
          c.code === CableType.NONE &&
          !this.cableDialogRef
        ) {
          this.pause();
          this.cableDialogRef = this.dialog.open(CableWarningDialogComponent);
          this.cableDialogRef
            .afterClosed()
            .pipe(filter((res) => Boolean(res)))
            .subscribe(() => {
              this.endTraining(true);
            });
        }
      })
    );
  }

  onStart(muscles: SelectedMuscle[]): void {
    this.muscles = muscles
      .map((m) => ({ ...m }))
      .sort((a, b) => a.channel - b.channel);
    const isPelvic =
      muscles.findIndex((m) => m.channel === 6 || m.channel === 7) >= 0;
    this.selectedChannel = isPelvic
      ? this.muscles[this.muscles.length - 1].channel
      : this.muscles[0].channel;
  }

  onStop(): void {
    this.exerciseService.update({
      duration: (this.testController?.getCurrentTime() ?? 0) * 1e6,
    });
  }

  onPause(): void {
    this.exerciseService.update({
      duration: (this.testController?.getCurrentTime() ?? 0) * 1e6,
    });
  }

  startTraining(steps: any[]): void {
    this.initialSetupDone = true;
    this.steps = steps;

    this.initializeEmgExercise(this.needsCalibration())
      .pipe(
        tap(() => this.initChart()),
        flatMap(() => this.performInstructionDialog()),
        flatMap(() => this.performAdditionalInstructionDialog()),
        tap(
          () =>
            (this.initializeExerciseDialog = this.dialog.open(
              InitializeExerciseDialogComponent
            ))
        ),
        flatMap((_) => this.initStella()),
        tap(() => this.initTest()),
        flatMap(() => this.initializeExercise()),
        tap(() => {
          this.trainingReady = true;
          this.initializeExerciseDialog.close();
        })
      )
      .subscribe();
  }

  protected initTest(): void {
    const dispatcher = new ActionDispatcher({
      sound: (event) => {
        this.playSound({
          type: event.type,
          details: event.detail,
        });
      },
      line: (event) => {
        const line = [event.line].map(this.mapLine);
        this.chart.addVerticalLine(line[0].value, line[0].borderColor);
      },
      threshold: () => {},
      end: (_) => {
        this.endTraining(true);
      },
    });
    this.subscriptions.add(
      this.testController.event$
        .pipe(tap((event) => dispatcher.dispatch(event)))
        .subscribe()
    );
  }

  protected initChart() {
    const { lines, thresholdLine } =
      this.testController.prepareLineConfiguration({
        startTime: 0,
        max: 100,
        lineCreator: (type, time) => this.mapLine(type, time),
      });
    this.guideLine = thresholdLine;
    this.chart = new LineChart2({ container: "line-chart" }, this.translation.instant("common.units.xAxisSeconds"), this.translation.instant("common.units.yAxisMicrovolts"));
    this.chart.setScale({
      min: 0,
      max: this.vals$.value.max,
    });
    // Guideline only for first selected channel
    // Should we draw more horiontal lines?
    this.chart.createGuideLine(
      thresholdLine[
        this.muscles.findIndex((item) => item.channel === this.selectedChannel)
      ],
      getColorForChannel(this.selectedChannel).hex()
    );
    this.chart.setVisibleChannels(this.displayedChannels);
    this.chart.setCurrentChannel(this.selectedChannel);
    lines.forEach((l) => {
      this.chart.addVerticalLine(l.value, l.borderColor);
    });
    this.chartController = new BaseChartController(this.chart);
    this.chartController.updateChannel(this.selectedChannel);
  }

  protected needsCalibration(): boolean {
    if (this.test && this.test.steps) {
      return this.test.steps.calibration;
    }
    return false;
  }

  protected createTestController(): TestController {
    return new StepTestController({
      ...this.test.steps,
      steps: this.steps,
      ports: this.vals$.value.ports,
    });
  }

  protected performInstructionDialog(): Observable<any> {
    if (this.test.steps.instructions) {
      return this.dialog
        .open(MessageDialogComponent, {
          data: {
            translated: true,
            prompt: this.test.steps.instructions,
          },
        })
        .afterClosed();
    }
    return of({});
  }

  protected performAdditionalInstructionDialog(): Observable<any> {
    if (this.dashboard.exercise?.template?.steps?.additionalInstructions) {
      return this.dialog
        .open(MessageDialogComponent, {
          data: {
            translated: false,
            prompt:
              this.dashboard.exercise.template.steps.additionalInstructions,
          },
        })
        .afterClosed();
    }
    return of({});
  }

  protected initializeEmgExercise(performEmg = true, setSingleChannel = true): Observable<any> {
    if (!performEmg) {
      return of({}).pipe(delay(100));
    }
    return this.dialog
      .open(EmgCalibrationComponent, {
        width: "80%",
        data: {
          muscles: this.muscles,
          channel: this.selectedChannel,
          recalibration: false,
        },
      })
      .afterClosed()
      .pipe(
        map((result) => {
          if (!result) {
            this.exerciseContainer.restart();
            this.restart();
          }
          return result;
        }),
        filter(Boolean),
        tap((result: any) => {
          this.selectedChannel = result.channel;
          if( setSingleChannel ) {
            this.setDisplayedChannels([this.selectedChannel]);
          }
          this.maxes = result.max;

          // ports containts mvc data for all selected channels
          // there is no need for quality channel check / all is done before emg calibration
          const ports: DiagnosticTestPort[] = [];
          for (const item in this.muscles) {
            ports.push({
              channel: this.muscles[item].channel,
              mvc: this.maxes[item],
            });
          }

          this.vals$.next({
            ports,
            max: +localStorage.getItem(STELLA_LAST_CHART_SCALE),
          });
          this.sessionService.updateMax(this.maxes);
        }),
        delay(100),
        tap((_) => (this.testController = this.createTestController()))
      );
  }

  protected handleData(signal: ChannelSignal): void {
    Object.entries<Float32Array>(signal).forEach(([key, vl]) => {
      const time = this.chartController.getExerciseTimePerChannel(Number(key)) / 1000;
      if (vl.length > 50) {
        for (let i = 0; i < vl.length; i += 50) {
          this.chart.addValue(
            {
              y: vl[i] * 1e6,
              x: time + i / 1000,
            },
            Number(key)
          );
        }
      } else {
        this.chart.addValue(
          {
            y: vl[0] * 1e6,
            x: time,
          },
          Number(key)
        );
      }
    });
    const time = this.chartController.addData(signal) / 1000;
    this.testController.updateTime(time);    

    // collect data for all channels
    // key in signal is channel number
    Object.keys(signal).forEach((key) => {
      // sometimes signal hasn't data for all channels, so idx in array is useless
      const chIdx = this.muscles.findIndex(
        (item) => item.channel === Number(key)
      );
      const guideLevel = this.findGuideValueForTime(chIdx, time);
      this.exerciseService.updateGuideLine(
        new Float32Array(Object.values(signal[key]).length).fill(guideLevel),
        Number(key)
      );
    });
  }

  protected restart() {
    this.initialSetupDone = false;
  }

  changeScale(value) {
    super.changeScale(value);
    this.vals$.next({
      ports: this.vals$.value.ports,
      max: value,
    });
  }

  playSound(soundInfo: SoundInfo) {
    if (!this.muted) {
      this.player.play(soundInfo, this.translation.currentLang);
    }
  }

  protected mapLine(type: string, time: number): ChartLine {
    const colors = {
      [MovementType.CONTRACTION]: "#ff8800",
      [MovementType.RELAX]: "#24B939",
      END: "#66cccc",
    };
    return {
      type: "line",
      mode: "vertical",
      scaleID: "x-axis-0",
      value: time,
      borderColor: colors[type],
      borderWidth: 2,
    };
  }

  private findGuideValueForTime(idx: number, time: number): number {
    const guidePoint = this.guideLine[idx].filter((m) => m.x <= time).pop();
    return guidePoint ? guidePoint.y : 0;
  }
}
